.cl-accordion-item {
  border: 1px solid #c4c5d0;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 15px;
  margin-top: 20px;
}
.cl-accordion-item:hover {
  border: 1px solid #000000;
}
.accordion-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.accordion-remian-pay {
  margin-left: 10px;
}
.accordion-title-txt {
  display: flex;
  font-weight: 400;
  font-size: 20px;
  margin-left: 10px;
  text-align: center;
}
.accordion-title-txt-desc {
  color: #04af70;
}
.accordion-remian-pay-price {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
}
.accordion-remian-pay-txt {
  color: #167a93;
  font-weight: 500;
  font-size: 16px;
}
.cl-down-arrow {
  height: 40px;
  width: 40px;
  padding-left: 10px;
}
.cl-right-arrow {
  height: 40px;
  width: 40px;
  padding-left: 10px;
}
.accordion-content {
  margin-top: 10px;
}
