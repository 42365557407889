$black: #000000;
$blackHover: #a3a3a3;
$white: #ffffff;
$offWhite: #f8f8f8;
$gray: #a3a3a3;
$lightGray: #c4c5d0;
$darkGray: #3e4958;
$lightPink: #ffbbc2;
$red: #f44336;
$lightGreen: #2ecc71;
