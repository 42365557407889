@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./../../utils/AppColors";

.product-details-title {
  font-size: 25px;
  width: 300px;
}
.product-item-div {
  width: 400px;
  margin-left: 20px;
}
.product-title {
  font-size: 20px;
  font-weight: 600;
}
.product-desc {
  font-size: 18px;
  font-weight: 500;
  color: $darkGray;
}
