.login-main-container {
  height: 100vh;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("https://news.sophos.com/wp-content/uploads/2019/10/shutterstock_1078811981.jpg");
  /* background-color: #000; */
  background-size: cover;
  background-repeat: no-repeat;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  height: auto;
  margin: auto;
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}
.al-logo {
  margin-top: 5px;
  height: 50px;
  width: 50px;
}
.al-title {
  color: rgba(101, 101, 101, 1);
  margin-top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  text-align: center;
}
.button {
  color: #fff;
  background: #000;
  border: #000;
  height: 40px;
  width: calc(100% - 40%);
  font-size: 18px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 500;
  text-align: center;
}
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button-container a {
  margin-top: 10px;
}

/* Header Profile button */

.profile-button {
  display: flex;
  justify-content: center;
}
.profile-button-container {
  display: flex;
}
.profile-button-change {
  width: 150px;
  height: 40px;
  background: #167a93;
  box-shadow: 0px 4px 6px -1px rgba(22, 122, 147, 0.1),
    0px 2px 4px -1px rgba(22, 122, 147, 0.06);
  border-radius: 10px;
  color: #fff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border: #167a93;
}
.profile-button-change {
  outline: none;
}
.profile-button-cancel {
  width: 150px;
  background: #fff;
  box-shadow: 0px 4px 6px -1px rgba(22, 122, 147, 0.1),
    0px 2px 4px -1px rgba(22, 122, 147, 0.06);
  border-radius: 10px;
  color: #000;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  margin-right: 10px;
}

@media (max-width: 1300px) {
  .login-main-container {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 800px) {
  .login-main-container {
    display: flex;
    justify-content: center;
    background-position: center;
  }
}
