@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./../../utils/AppColors";

.dash-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.dash-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $black;
  padding: 20px;
  border-radius: 8px;
  height: 250px;
  width: 250px;
  margin: 20px;
}
.item-name {
  color: $white;
  font-size: 25px;
  margin-top: 20px;
  font-weight: 600;
}
